import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import RequireAuth from 'components/User/RequireAuth';
import { Paths } from 'utils/constants/routes';

const NotFound = lazy(() => import('pages/NotFound'));
const Tagger = lazy(() => import('pages/Tagger'));
const Tracks = lazy(() => import('pages/Tracks'));
const Playlists = lazy(() => import('pages/Playlists'));
const Playlist = lazy(() => import('pages/Playlist'));
const PlaylistPublic = lazy(() => import('pages/PlaylistPublic'));
const PlaylistPublicCode = lazy(() => import('pages/PlaylistPublic/PlaylistPublicCode'));
const ChangePassword = lazy(() => import('pages/User/changePassword'));
const PasswordRecovery = lazy(() => import('pages/User/passwordRecovery'));
const SignIn = lazy(() => import('pages/User/signIn'));

const Router = () => (
  <Suspense fallback={<></>}>
    <Routes>
      <Route
        path={Paths.root}
        element={
          <RequireAuth>
            <Tracks />
          </RequireAuth>
        }
      />
      <Route
        path={Paths.track}
        element={
          <RequireAuth>
            <Tagger />
          </RequireAuth>
        }
      />
      <Route
        path={Paths.playlists}
        element={
          <RequireAuth>
            <Playlists />
          </RequireAuth>
        }
      />
      <Route
        path={Paths.playlist}
        element={
          <RequireAuth>
            <Playlist />
          </RequireAuth>
        }
      />
      <Route path={Paths.playlistPublic} element={<PlaylistPublic />} />
      <Route path={Paths.playlistPublicCode} element={<PlaylistPublicCode />} />
      <Route path={Paths.signIn} element={<SignIn />} />
      <Route path={Paths.passwordRecovery} element={<PasswordRecovery />} />
      <Route path={Paths.changePassword} element={<ChangePassword />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>
);

export default Router;
