import { gql } from '@apollo/client';

import { client } from 'services/client';
import { SignInInput } from 'types/user';

const QUERY_USER_INFO = gql(`
  query UserInfo($orgId: ID) {
    currentIdentity {
      ... on Identity {
        orgUsers(orgId: $orgId) {
          organization {
            id
            name
          }
          orgRole {
            orgRolePermissions {
              action
            }
          }
        }
        user {
          id
          email
          name
          isSuperadmin
        }
      }
    }
  }
`);

const QUERY_AVAILABLE_ORGS = gql(`
  query AvailableOrgs {
    currentIdentity {
      ... on Identity {
        orgUsers {
          organization {
            id
            name
          }
        }
      }
    }
  }
`);

const SIGN_IN_MUTATION = gql(`
  mutation SignIn($input: SessionCreateWithPasswordInput!) {
    session {
      createWithPassword(input: $input) {
        sessionTokens {
          accessToken
          refreshToken
          expiresAt
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const LOG_OUT_MUTATION = gql(`
  mutation LogOut($input: SessionRevokeInput!) {
    session {
      revoke(input: $input) {
        result

        error {
          code
          message
        }
      }
    }
  }
`);

const QUERY_USER_VIDEO_CLIPS = gql(`
  query UserVideoClips($orgId: ID!) {
    videoClips(orgId: $orgId) {
      ... on VideoClipConnection {
        nodes {
          id
          video {
            name
            transcodedAttachmentUrl: transcodedMediaUrl(asAttachment: true, clck: false)
            transcodedByteSize
          }
        }
      }
      ... on VideoClipsQueryDomainError {
      code
      message
    }
    }
  }
`);

export const userService = {
  async getUser(orgId?: string) {
    const result = await client.query({
      query: QUERY_USER_INFO,
      variables: {
        orgId,
      },
    });

    const { errors, data } = result;

    if (errors) {
      return Promise.reject(result.errors);
    }

    return data.currentIdentity;
  },

  async getAvailableOrgs() {
    const result = await client.query({
      query: QUERY_AVAILABLE_ORGS,
    });

    const { errors, data } = result;

    if (errors) {
      return Promise.reject(result.errors);
    }

    return data.currentIdentity.orgUsers;
  },

  async signIn(input: SignInInput) {
    const result = await client.mutate({
      mutation: SIGN_IN_MUTATION,
      variables: {
        input,
      },
    });

    const { data } = result;

    if (data.session.createWithPassword.error) {
      return Promise.reject(data.session.createWithPassword.error);
    }

    return data.session.createWithPassword.sessionTokens;
  },

  async logOut() {
    const result = await client.mutate({
      mutation: LOG_OUT_MUTATION,
      variables: {
        input: {},
      },
    });

    const { errors, data } = result;

    if (errors) {
      return Promise.reject(result.errors);
    }

    if (data.session.revoke.error) {
      return Promise.reject(data.session.revoke.error);
    }

    return data.session.revoke;
  },

  async getUserVideoClips(orgId: string) {
    const result = await client.query({
      query: QUERY_USER_VIDEO_CLIPS,
      variables: {
        orgId,
      },
    });

    if (result.data.videoClips.__typename === 'VideoClipsQueryDomainError') {
      return Promise.reject(result.data.videoClips);
    }

    return result.data.videoClips.nodes;
  },
};

export default userService;
